import * as Sentry from "@sentry/react";
import { useEffect, useState } from "react";

type MediaPermissions = {
  cameraDisabled: boolean;
  microphoneDisabled: boolean;
};

const useMediaPermissions = (): MediaPermissions => {
  const [permissions, setPermissions] = useState<MediaPermissions>({
    cameraDisabled: false,
    microphoneDisabled: false,
  });

  useEffect(() => {
    const checkPermissions = async () => {
      try {
        const cameraPermission = await navigator.permissions.query({
          name: "camera" as PermissionName,
        });
        const microphonePermission = await navigator.permissions.query({
          name: "microphone" as PermissionName,
        });

        setPermissions({
          cameraDisabled: cameraPermission.state !== "granted",
          microphoneDisabled: microphonePermission.state !== "granted",
        });

        cameraPermission.onchange = () => {
          setPermissions((prev) => ({
            ...prev,
            cameraDisabled: cameraPermission.state !== "granted",
          }));
        };

        microphonePermission.onchange = () => {
          setPermissions((prev) => ({
            ...prev,
            microphoneDisabled: microphonePermission.state !== "granted",
          }));
        };
      } catch (error) {
        Sentry.addBreadcrumb({
          message: "Error checking media permissions",
          category: "error",
          level: "error",
          data: { error },
        });
        setPermissions({
          cameraDisabled: false,
          microphoneDisabled: false,
        });
      }
    };

    checkPermissions();
  }, []);

  return permissions;
};

export default useMediaPermissions;
