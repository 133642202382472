import { useQuery } from "@apollo/client";
import { addHours } from "date-fns";
import React, { Suspense, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import NotFoundScreen from "../404";
import { MeetingStatus, MeetingType } from "../api/graphql";
import useApolloErrorHandler from "../lib/handleApolloError";
import StreamTrackerProvider from "../lib/StreamTrackerProvider";
import { useCurrentCorrespondent } from "../SessionBoundary";
import CallSkeleton from "./CallSkeleton";
import { GetMeetingInfoDocument } from "./GetMeetingInfo.generated";
import LiveCall from "./LiveCall";
import Lobby from "./Lobby";
import Rating from "./Rating";

type CallPhase =
  | "lobby"
  | "call"
  | "ended"
  | "terminated"
  | "connectedElsewhere";

export default function VideoCallScreen() {
  const { id } = useParams<{ id: string }>();

  const { t } = useTranslation();
  const user = useCurrentCorrespondent();
  const [phase, setPhase] = useState<CallPhase>("lobby");
  const [beginMuted, setBeginMuted] = useState(false);
  const [beginHidden, setBeginHidden] = useState(false);
  const [hasPeerEverJoined, setHasPeerEverJoined] = useState(false);
  const [userLeftCall, setUserLeftCall] = useState(false);
  const handleApolloError = useApolloErrorHandler();

  if (!id) throw new Error("Missing meeting id");

  const { data, loading, error, refetch } = useQuery(GetMeetingInfoDocument, {
    fetchPolicy: "no-cache", // do not cache access token
    variables: { meetingId: id, checkVoiceCallLimit: false },
    onError: (e) => handleApolloError(e),
  });
  if (error) throw error;

  const denied = useMemo(
    () =>
      data &&
      user.__typename === "Visitor" &&
      data.meeting.primaryVisitor &&
      user.id !== data.meeting.primaryVisitor.id,
    [user.id, user.__typename, data]
  );

  const meetingIsJoinable = !!data?.meeting.call?.url;
  const meetingHasEnded =
    data &&
    [
      MeetingStatus.Ended,
      MeetingStatus.Terminated,
      MeetingStatus.NoShow,
    ].includes(data.meeting.status);

  // Sets the default muted state for webinar attendees
  useEffect(() => {
    if (
      data?.meeting.meetingType === MeetingType.Webinar &&
      user.__typename === "Inmate"
    ) {
      setBeginMuted(true);
    }
  }, [data, user]);

  // poll for meeting information. mostly useful for on-demand meetings but
  // also for incidents where CVH doesn't start on time.
  useEffect(() => {
    if (!data || meetingIsJoinable || meetingHasEnded) return () => {};
    const timer = setInterval(refetch, 30000);
    return () => clearInterval(timer);
  }, [data, meetingIsJoinable, meetingHasEnded, refetch]);

  // collect feedback for an ended call, but only within an hour of the ending
  useEffect(() => {
    if (
      meetingHasEnded &&
      Date.now() < addHours(data.meeting.interval.endAt, 1).getTime()
    ) {
      setPhase("ended");
    }
  }, [meetingHasEnded, data?.meeting.interval.endAt]);

  // loading or transitioning
  if (loading || !data?.meeting) return <CallSkeleton />;

  // if the meeting is pending approval, scheduled, or live, do not 404,
  // let the user enter the lobby
  if (
    denied ||
    (![MeetingStatus.Scheduled, MeetingStatus.Live].includes(
      data.meeting.status
    ) &&
      phase !== "ended")
  ) {
    // phase is 'ended' if we already determined that the call ended today.
    // This is for all other statuses other than pending approval, scheduled,
    // and live, or if Ended/Terminated call is from before today.
    return <NotFoundScreen />;
  }

  let children: React.ReactNode;
  if (phase === "lobby") {
    children = (
      <Lobby
        onJoinButtonClick={
          meetingIsJoinable ? () => setPhase("call") : undefined
        }
        meeting={data.meeting}
        beginMuted={beginMuted}
        beginHidden={beginHidden}
        onToggleAudio={() => setBeginMuted((m) => !m)}
        onToggleVideo={() => setBeginHidden((p) => !p)}
      />
    );
  } else if (phase === "call") {
    children = (
      <Suspense fallback={<CallSkeleton />}>
        <LiveCall
          meeting={data.meeting}
          onLeave={() => {
            setUserLeftCall(true);
            setPhase("ended");
          }}
          beginHidden={beginHidden}
          beginMuted={beginMuted}
          alerts={false}
          onToggleAlerts={() => {}}
          onPeerJoined={() => {
            setHasPeerEverJoined(true);
          }}
          onTerminated={() => setPhase("terminated")}
          onConnectedElsewhere={() => setPhase("connectedElsewhere")}
          onAudioReady={() => {}}
          onEnded={() => setPhase("ended")}
        />
      </Suspense>
    );
  } else {
    children = (
      <Rating
        title={userLeftCall ? t("You left the call") : t("This call has ended")}
        meeting={data.meeting}
        collectRating={hasPeerEverJoined}
        terminated={phase === "terminated"}
        connectedElsewhere={phase === "connectedElsewhere"}
        rejoinCall={
          phase === "connectedElsewhere" ? () => setPhase("lobby") : undefined
        }
        allowTextFeedback={user.__typename === "Visitor"}
      />
    );
  }

  return <StreamTrackerProvider>{children}</StreamTrackerProvider>;
}
