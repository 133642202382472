import { useMutation } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { track } from "../../analytics";
import { IdDocumentType } from "../../api/graphql";
import useApolloErrorHandler from "../../lib/handleApolloError";
import { dateToIso } from "../../lib/timeFormats";
import useFileUpload from "../../lib/useFileUpload";
import { useCurrentVisitor } from "../../SessionBoundary";
import { UpdateVisitorIdentityDocument } from "../UpdateVisitorIdentity.generated";
import { ConfirmStepData } from "./ConfirmDataStep";
import { CreateVisitorIdentityDocument } from "./CreateVisitorIdentity.generated";
import { DocumentTypeStepData } from "./DocumentTypeStep";
import { UploadPhotosStepData } from "./UploadPhotosStep";

type Props = {
  action: "create" | "edit";
  onIDSubmitted?: () => void;
};

export type SubmitIDFormData = Partial<
  DocumentTypeStepData & UploadPhotosStepData & ConfirmStepData
>;

export default function useSubmitID({ action, onIDSubmitted }: Props) {
  const upload = useFileUpload();
  const currentVisitor = useCurrentVisitor();
  const handleApolloError = useApolloErrorHandler();

  // Mutations
  const [createIdentity, { loading: creatingIdentity }] = useMutation(
    CreateVisitorIdentityDocument,
    {
      onError: (e) => handleApolloError(e),
      onCompleted: () => {
        if (onIDSubmitted) onIDSubmitted();
      },
    }
  );

  const [updateIdentity, { loading: updatingIdentity }] = useMutation(
    UpdateVisitorIdentityDocument,
    {
      onError: (e) => handleApolloError(e),
      onCompleted: () => {
        if (onIDSubmitted) onIDSubmitted();
      },
      update: (cache, { data }) => {
        if (!data) return;
        cache.modify({
          id: cache.identify({
            __typename: "Visitor",
            id: currentVisitor.id,
          }),
          fields: {
            systemRelationships(existing) {
              return {
                ...existing,
                ...data.updateVisitorIdentity.visitor.systemRelationships,
              };
            },
          },
        });
      },
    }
  );

  const submitIdentity = async (data: SubmitIDFormData) => {
    const { firstName, lastName, dateOfBirth, documentType, idImageThree } =
      data;
    // Required because of Partial in SubmitIDFormData

    const requiresOnlyOneImage = documentType === IdDocumentType.Undocumented;

    // Undocumented flow only needs a selfie, so we upload the document three times.
    const idImageOne = requiresOnlyOneImage ? idImageThree : data.idImageOne;
    const idImageTwo = requiresOnlyOneImage ? idImageThree : data.idImageTwo;

    if (
      !firstName ||
      // Last name is not required anymore
      !dateOfBirth ||
      !documentType ||
      !idImageOne ||
      !idImageTwo ||
      !idImageThree
    ) {
      Sentry.captureMessage("Missing required data for ID submission", "error");
      return false;
    }

    const imageOneKey = await upload(idImageOne);
    const imageTwoKey = requiresOnlyOneImage
      ? imageOneKey
      : await upload(idImageTwo);
    const imageThreeKey = requiresOnlyOneImage
      ? imageOneKey
      : await upload(idImageThree);

    const input = {
      firstName,
      lastName,
      dateOfBirth: dateToIso(new Date(dateOfBirth)),
      documentType,
      imageOneKey,
      imageTwoKey,
      imageThreeKey,
    };

    // Create
    if (action === "create") {
      await createIdentity({
        variables: {
          input,
        },
      });
      return true;
    }
    // Update
    await updateIdentity({
      variables: {
        input,
      },
    });
    track("ID submitted", { documentType });
    return true;
  };
  return { submitIdentity, creatingIdentity, updatingIdentity };
}
