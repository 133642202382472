import { groupBy, sortByStrings, uniq } from "@ameelio/core";
import { useSnackbarContext } from "@ameelio/ui";
import { useMutation, useQuery } from "@apollo/client";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { track } from "../analytics";
import { Facility } from "../api/graphql";
import { appendItem } from "../client";
import FlowSkeleton from "../FlowSkeleton";
import FullscreenFlow from "../FullscreenFlow";
import { AvailableCountry } from "../lib/DocumentType";
import getWorkflowState from "../lib/getWorkflowState";
import useApolloErrorHandler from "../lib/handleApolloError";
import isMinor, { isYoungerThan } from "../lib/isMinor";
import { PersonalRelationship } from "../lib/Relationship";
import ResetScroll from "../lib/ResetScroll";
import StepperWrapper from "../lib/StepperWrapper";
import { getRawDateTzUnaware } from "../lib/timeFormats";
import useOnLoad from "../lib/useOnLoad";
import {
  currentMembership,
  useCurrentVisitor,
  useSelectableCountry,
} from "../SessionBoundary";
import ConfirmDataStep, {
  ConfirmStepData,
} from "../Settings/IdentificationFlow/ConfirmDataStep";
import DocumentTypeStep, {
  DocumentTypeStepData,
} from "../Settings/IdentificationFlow/DocumentTypeStep";
import UploadPhotosStep, {
  UploadPhotosStepData,
} from "../Settings/IdentificationFlow/UploadPhotosStep";
import useSubmitID, {
  SubmitIDFormData,
} from "../Settings/IdentificationFlow/useSubmitID";
import ConnectionsSearchForm from "./ConnectionsSearchForm";
import ConnectionsSearchMinorsForm from "./ConnectionsSearchMinorsForm";
import ConnectionsSelectOneForm from "./ConnectionsSelectOneForm";
import ConnectionsSelectOneMinorForm from "./ConnectionsSelectOneMinorForm";
import { CreatePendingConnectionDocument } from "./CreatePendingConnection.generated";
import { CreatePendingProfessionalConnectionDocument } from "./CreatePendingProfessionalConnection.generated";
import FacilityVisitationFormLinks from "./FacilityVisitationFormLinks";
import { GetAllFacilitiesDocument } from "./GetAllFacilities.generated";
import RelationshipForm from "./RelationshipForm";
import { getSteps, Step } from "./steps";
import {
  FacilitiesMap,
  MinorSearchCriteria,
  RelationshipFormCriteria,
  SearchCriteria,
  SearchResult,
} from "./types";

type Props = {
  backTo?: string;
  parentName?: string;
};

export default function ConnectionsFlow({ backTo, parentName }: Props) {
  const { t } = useTranslation();
  const snackbarContext = useSnackbarContext();
  const handleApolloError = useApolloErrorHandler();
  const currentVisitor = useCurrentVisitor();
  const navigate = useNavigate();

  const satisfiedIDRequirementByIdentity = !!currentVisitor.identity;

  const satisfiedIDRequirementByAge = (age?: number) =>
    age ? isYoungerThan(age, currentVisitor) : isMinor(currentVisitor);

  const [currentStep, setCurrentStep] = useState<Step>("searchInmates");

  const defaultCountry = useSelectableCountry();
  const [selectedCountry, setSelectedCountry] =
    useState<AvailableCountry>(defaultCountry);
  const [selectedProvince, setSelectedProvince] = useState<string | "">("");
  const [selectedResult, setSelectedResult] = useState<SearchResult>();

  // Provider data
  const membership = currentMembership(currentVisitor);
  const organization = membership?.organization;

  const shouldShowRelationshipStep = !organization;
  const location = useLocation();
  const state = getWorkflowState(location) ?? {
    fromPath: backTo || "/contacts",
    fromName: parentName || t("Contacts"),
  };

  // Step management
  // At initialization, we don't know we will be searching for
  // a minor (false) and don't know if we will need to show a
  // visitation form (false)
  const [steps, setSteps] = useState<Step[]>(
    getSteps({
      satisfiedIDRequirement: satisfiedIDRequirementByIdentity,
      relationship: shouldShowRelationshipStep,
      searchMinors: false,
      visitationForms: false,
    })
  );

  // Search
  const [searchCriteria, setSearchCriteria] = useState<SearchCriteria>();
  const [minorsSearchCriteria, setMinorsSearchCriteria] =
    useState<MinorSearchCriteria>();

  // Identity
  const [idFormData, setIDFormData] = useState<SubmitIDFormData>({});
  const { submitIdentity, creatingIdentity, updatingIdentity } = useSubmitID({
    action: "create",
    onIDSubmitted: () => setCurrentStep("finished"),
  });

  // Facility data
  const { data: allFacilitiesData, error: allFacilitiesError } = useQuery(
    GetAllFacilitiesDocument,
    {
      fetchPolicy: "cache-and-network",
    }
  );
  if (allFacilitiesError) throw new Error("Failed to load facility data.");

  const {
    facilitiesMap,
    availableFacilitiesInSelectedState,
    availableFacilitiesInSelectedStateIds,
  } = useMemo(() => {
    const facilities = allFacilitiesData?.facilities || [];

    type CountryFacility = {
      [key: string]: Facility[];
    };

    const facilitiesByCountry: CountryFacility = groupBy(
      facilities,
      (fac) => fac.country
    ) as CountryFacility;

    const provincesFacilitiesByCountry: FacilitiesMap = {};
    Object.keys(facilitiesByCountry).forEach((country) => {
      provincesFacilitiesByCountry[country] = {
        provinces: uniq(facilitiesByCountry[country].map((r) => r.province)),
        facilities: facilitiesByCountry[country],
      };
    });

    // The previous version of search depended on the selected State, while the new one may have no state available.
    const selectedCountryFacilities =
      provincesFacilitiesByCountry[selectedCountry]?.facilities || [];
    const availableFacilities = sortByStrings(
      selectedCountryFacilities.filter(
        (f) => !selectedProvince || f.province === selectedProvince
      ),
      (r: { name: string }) => r.name
    );

    const availableFacilitiesItems = availableFacilities.map((f) => ({
      value: f.id,
      name: `${f.name} (${f.publicId})`,
    }));

    return {
      facilitiesMap: provincesFacilitiesByCountry,
      availableFacilitiesInSelectedState: availableFacilitiesItems,
      availableFacilitiesInSelectedStateIds: availableFacilities.map(
        (f) => f.id
      ),
    };
  }, [allFacilitiesData, selectedCountry, selectedProvince]);

  useOnLoad(() => {
    track("Contact request - Started");
  });
  const trackSuccess = () => {
    track("Contact request - Success", {
      foundMinor: !!minorsSearchCriteria,
      countryFilter: selectedCountry,
      provinceFilter: selectedProvince,
      facilityFilter:
        searchCriteria && "facilityId" in searchCriteria
          ? searchCriteria.facilityId
          : undefined,
    });
  };
  const trackFailure = (e: unknown) => {
    track("Contact request - Failure", {
      reason: e instanceof Error ? e.message : "unknown",
    });
  };

  const [
    createPendingProfessionalConnection,
    { loading: creatingPendingProConnection },
  ] = useMutation(CreatePendingProfessionalConnectionDocument, {
    onError: (e) => {
      handleApolloError(e);
      trackFailure(e);
    },
    onCompleted: trackSuccess,
    update: (cache, { data }) => {
      if (!data) return;
      cache.modify({
        id: cache.identify({ __typename: "Visitor", id: currentVisitor.id }),
        fields: {
          connections: appendItem(
            data.createPendingProfessionalConnection.connection
          ),
        },
      });
    },
  });

  const [createPendingConnection, { loading: creatingPendingConnection }] =
    useMutation(CreatePendingConnectionDocument, {
      onError: (e) => {
        handleApolloError(e);
        trackFailure(e);
      },
      onCompleted: trackSuccess,
      update: (cache, { data }) => {
        if (!data) return;
        cache.modify({
          id: cache.identify({ __typename: "Visitor", id: currentVisitor.id }),
          fields: {
            connections: appendItem(data.createPendingConnection.connection),
          },
        });
      },
    });

  const handleSendRequestSubmit = async (selected: SearchResult) => {
    setSelectedResult(selected);
    const result = membership
      ? await createPendingProfessionalConnection({
          variables: {
            input: {
              inmateId: selected.id,
              organizationMembershipId: membership.id,
            },
          },
        })
      : await (() => {
          if (!selected.relationship)
            throw new Error(
              "Cannot create a connection without selecting a relationship"
            );
          return createPendingConnection({
            variables: {
              input: {
                inmateId: selected.id,
                relationship: selected.relationship,
              },
            },
          });
        })();
    // Assume that if the first request succeeded, all succeeded;
    // and if not, that there was an error snackbar displayed.
    if (result.data) {
      setCurrentStep("finished");
    }
  };

  if (!allFacilitiesData) return <FlowSkeleton />;

  if (currentStep === "finished") {
    const message = t(
      "Your request to connect with {{fullName}} will be reviewed by facility staff shortly.",
      { fullName: selectedResult?.fullName }
    );
    snackbarContext.alert("success", message);
    navigate(state.fromPath);
    return null;
  }

  return (
    <FullscreenFlow
      backTo={state.fromPath}
      breadcrumbs={[state.fromName, t("Request contact")]}
    >
      <ResetScroll key={currentStep} />
      {currentStep === "searchInmates" && (
        <ConnectionsSearchForm
          stepper={({ loading = false, disabled }) =>
            StepperWrapper({
              onBack: () => {
                navigate(state.fromPath);
              },
              buttonType: "next",
              loading,
              disabled,
              steps,
              step: currentStep,
            })
          }
          facilitiesMap={facilitiesMap}
          selectedCountry={selectedCountry}
          selectedProvince={selectedProvince}
          onSubmit={(criteria: SearchCriteria) => {
            setSelectedProvince(criteria.province || "");
            setSelectedCountry(criteria.country || AvailableCountry.US);
            setSearchCriteria(criteria);
            setCurrentStep("results");
          }}
        />
      )}
      {currentStep === "results" && searchCriteria && (
        <ConnectionsSelectOneForm
          stepper={({ loading = false, disabled }) =>
            StepperWrapper({
              onBack: () => setCurrentStep("searchInmates"),
              loading:
                !!loading ||
                creatingPendingConnection ||
                creatingPendingProConnection,
              disabled,
              steps,
              step: currentStep,
            })
          }
          partneredFacilityIds={availableFacilitiesInSelectedStateIds}
          searchCriteria={searchCriteria}
          onSearchMinors={() => {
            setSteps(
              getSteps({
                satisfiedIDRequirement: satisfiedIDRequirementByIdentity,
                searchMinors: true,
                relationship: shouldShowRelationshipStep,
                visitationForms: false,
              })
            );
            setCurrentStep("searchMinors");
          }}
          onResultSelected={(selected: SearchResult) => {
            const satisfiedIDRequirement =
              satisfiedIDRequirementByIdentity ||
              satisfiedIDRequirementByAge(
                selected.facilityMinimumAgeIdRequired
              );
            setSteps(
              getSteps({
                satisfiedIDRequirement,
                searchMinors: false,
                relationship: shouldShowRelationshipStep,
                visitationForms: !!selected.facilityVisitationFormLink,
              })
            );
          }}
          onSubmit={async (selected: SearchResult) => {
            const visitationForms = !!selected.facilityVisitationFormLink;
            const satisfiedIDRequirement =
              satisfiedIDRequirementByIdentity ||
              satisfiedIDRequirementByAge(
                selected.facilityMinimumAgeIdRequired
              );
            setSteps(
              getSteps({
                satisfiedIDRequirement,
                relationship: shouldShowRelationshipStep,
                searchMinors: false,
                visitationForms,
              })
            );
            if (shouldShowRelationshipStep) {
              setSelectedResult(selected);
              setCurrentStep("relationship");
              return;
            }
            if (visitationForms) {
              setSelectedResult(selected);
              setCurrentStep("visitationForms");
              return;
            }
            if (!satisfiedIDRequirement) {
              setSelectedResult(selected);
              setCurrentStep("documentType");
              return;
            }
            await handleSendRequestSubmit(selected);
          }}
        />
      )}
      {currentStep === "searchMinors" && (
        <ConnectionsSearchMinorsForm
          stepper={({ loading = false, disabled }) =>
            StepperWrapper({
              onBack: () => {
                setMinorsSearchCriteria(undefined);
                setSteps(
                  getSteps({
                    satisfiedIDRequirement: satisfiedIDRequirementByIdentity,
                    relationship: shouldShowRelationshipStep,
                    searchMinors: false,
                    visitationForms:
                      !!selectedResult?.facilityVisitationFormLink,
                  })
                );
                setCurrentStep("results");
              },
              buttonType: "next",
              loading,
              disabled,
              steps,
              step: currentStep,
            })
          }
          facilityOptions={availableFacilitiesInSelectedState}
          defaultValues={{
            facilityId:
              searchCriteria && "facilityId" in searchCriteria
                ? searchCriteria.facilityId
                : undefined,
            ...minorsSearchCriteria,
          }}
          onSubmit={(minorsCriteria) => {
            setMinorsSearchCriteria(minorsCriteria);
            setCurrentStep("minorsResults");
          }}
        />
      )}
      {currentStep === "minorsResults" && minorsSearchCriteria && (
        <ConnectionsSelectOneMinorForm
          stepper={({ loading = false, disabled }) =>
            StepperWrapper({
              onBack: () => setCurrentStep("searchMinors"),
              loading:
                !!loading ||
                creatingPendingConnection ||
                creatingPendingProConnection,
              disabled,
              steps,
              step: currentStep,
            })
          }
          minorSearchCriteria={minorsSearchCriteria}
          onResultSelected={(selected: SearchResult) => {
            const satisfiedIDRequirement =
              satisfiedIDRequirementByIdentity ||
              satisfiedIDRequirementByAge(
                selected.facilityMinimumAgeIdRequired
              );
            setSteps(
              getSteps({
                satisfiedIDRequirement,
                searchMinors: true,
                relationship: shouldShowRelationshipStep,
                visitationForms: !!selected.facilityVisitationFormLink,
              })
            );
          }}
          onSubmit={async (selected: SearchResult) => {
            const visitationForms = !!selected.facilityVisitationFormLink;
            const satisfiedIDRequirement =
              satisfiedIDRequirementByIdentity ||
              satisfiedIDRequirementByAge(
                selected.facilityMinimumAgeIdRequired
              );
            setSteps(
              getSteps({
                satisfiedIDRequirement,
                relationship: shouldShowRelationshipStep,
                searchMinors: true,
                visitationForms,
              })
            );
            if (shouldShowRelationshipStep) {
              setSelectedResult(selected);
              setCurrentStep("relationship");
              return;
            }
            if (visitationForms) {
              setSelectedResult(selected);
              setCurrentStep("visitationForms");
              return;
            }
            if (!satisfiedIDRequirement) {
              setSelectedResult(selected);
              setCurrentStep("documentType");
              return;
            }
            await handleSendRequestSubmit(selected);
          }}
        />
      )}
      {currentStep === "relationship" &&
        selectedResult &&
        shouldShowRelationshipStep && (
          <RelationshipForm
            relationship={selectedResult.relationship}
            stepper={({ loading, disabled }) =>
              StepperWrapper({
                onBack: () =>
                  minorsSearchCriteria
                    ? setCurrentStep("minorsResults")
                    : setCurrentStep("results"),
                loading:
                  !!loading ||
                  creatingPendingConnection ||
                  creatingPendingProConnection,
                disabled,
                steps,
                step: currentStep,
              })
            }
            onSubmit={async ({
              relationship,
              relationshipDetails,
            }: RelationshipFormCriteria) => {
              const newRelationship =
                relationship === PersonalRelationship.Other
                  ? relationshipDetails
                  : relationship;
              const satisfiedRequirementByAge = satisfiedIDRequirementByAge(
                selectedResult.facilityMinimumAgeIdRequired
              );
              const satisfiedIDRequirement =
                satisfiedIDRequirementByIdentity || satisfiedRequirementByAge;
              const visitationForms =
                !!selectedResult.facilityVisitationFormLink;
              setSteps(
                getSteps({
                  satisfiedIDRequirement,
                  relationship: shouldShowRelationshipStep,
                  searchMinors: true,
                  visitationForms,
                })
              );
              setSelectedResult({
                ...selectedResult,
                relationship: newRelationship,
              });
              if (visitationForms) {
                setCurrentStep("visitationForms");
                return;
              }
              if (!satisfiedIDRequirement) {
                setCurrentStep("documentType");
                return;
              }
              // Since useState is async, we don't have the relationship set on the selectedResult yet while we do this.
              await handleSendRequestSubmit({
                ...selectedResult,
                relationship: newRelationship,
              });
            }}
          />
        )}
      {currentStep === "visitationForms" && selectedResult && (
        <FacilityVisitationFormLinks
          selectedResult={selectedResult}
          stepper={({ loading, disabled }) =>
            StepperWrapper({
              onBack: () =>
                shouldShowRelationshipStep
                  ? setCurrentStep("relationship")
                  : minorsSearchCriteria
                    ? setCurrentStep("minorsResults")
                    : setCurrentStep("results"),
              loading:
                !!loading ||
                creatingPendingConnection ||
                creatingPendingProConnection,
              disabled,
              steps,
              step: currentStep,
            })
          }
          onNext={async () => {
            if (!selectedResult) {
              throw new Error("No search result to request");
            }
            // Must be the minimum age at all facilities among all results
            const satisfiedRequirementByAge = satisfiedIDRequirementByAge(
              selectedResult.facilityMinimumAgeIdRequired
            );
            const satisfiedIDRequirement =
              satisfiedIDRequirementByIdentity || satisfiedRequirementByAge;
            if (!satisfiedIDRequirement) {
              setCurrentStep("documentType");
              return;
            }
            await handleSendRequestSubmit(selectedResult);
          }}
        />
      )}
      {currentStep === "documentType" && (
        <DocumentTypeStep
          forcedCountry={selectedCountry}
          documentType={idFormData?.documentType}
          stepper={({ loading, disabled }) =>
            StepperWrapper({
              onBack: () => {
                const visitationForms =
                  !!selectedResult?.facilityVisitationFormLink;
                setCurrentStep(
                  visitationForms
                    ? "visitationForms"
                    : minorsSearchCriteria
                      ? "minorsResults"
                      : "results"
                );
              },
              buttonType: "next",
              loading: !!loading,
              disabled,
              steps,
              step: currentStep,
            })
          }
          onNext={(documentType: DocumentTypeStepData) => {
            setIDFormData({ ...idFormData, ...documentType });
            setCurrentStep("uploadIDPhotos");
          }}
        />
      )}
      {currentStep === "uploadIDPhotos" && (
        <UploadPhotosStep
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          documentType={idFormData.documentType!}
          stepper={({ loading, disabled }) =>
            StepperWrapper({
              onBack: () => setCurrentStep("documentType"),
              buttonType: "next",
              loading: !!loading,
              disabled,
              steps,
              step: currentStep,
            })
          }
          defaultValues={idFormData}
          onNext={(photosData: UploadPhotosStepData) => {
            setIDFormData({ ...idFormData, ...photosData });
            setCurrentStep("confirmIDData");
          }}
        />
      )}
      {currentStep === "confirmIDData" && (
        <ConfirmDataStep
          stepper={({ loading, disabled }) =>
            StepperWrapper({
              onBack: () => setCurrentStep("uploadIDPhotos"),
              buttonType: "finish",
              loading:
                !!loading ||
                creatingIdentity ||
                updatingIdentity ||
                creatingPendingConnection ||
                creatingPendingProConnection,
              disabled,
              steps,
              step: currentStep,
            })
          }
          defaultValues={{
            firstName: currentVisitor.firstName,
            lastName: currentVisitor.lastName,
            dateOfBirth:
              currentVisitor.__typename === "Visitor" &&
              currentVisitor.dateOfBirth
                ? getRawDateTzUnaware(currentVisitor.dateOfBirth)
                : new Date(),
          }}
          onNext={async (confirmData: ConfirmStepData) => {
            if (!selectedResult) {
              throw new Error("No search result to request");
            }
            const uploadSuccess = await submitIdentity({
              ...idFormData,
              ...confirmData,
            });
            if (uploadSuccess) {
              await handleSendRequestSubmit(selectedResult);
            } else {
              snackbarContext.alert(
                "error",
                t(
                  "An error occurred uploading the ID document. Please wait a moment and try again."
                )
              );
            }
          }}
        />
      )}
    </FullscreenFlow>
  );
}
