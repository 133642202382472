import { capitalize } from "@mui/material";
import * as Sentry from "@sentry/react";
import { useTranslation } from "react-i18next";
import { MeetingType } from "../api/graphql";
import facilityServiceLabel from "../lib/facilityServiceLabel";
import meetingTypeLabel from "../lib/meetingTypeLabel";
import {
  formatTime,
  formatTimeRange,
  shortMonthDate,
  shortMonthDateFormat,
  shortMonthDateTime,
  yearMonthDate,
} from "../lib/timeFormats";
import { useCurrentCorrespondent } from "../SessionBoundary";
import NotificationEventType from "./NotificationEventType";

export default function useNotificationContent(
  nt: NotificationEventType,
  params?: string | null
) {
  const { t } = useTranslation("notifications");
  const user = useCurrentCorrespondent();
  try {
    const notificationParams = JSON.parse(params || "{}");
    let notification: { title: string; body: string };
    switch (nt) {
      // Legacy
      case NotificationEventType.LEGACY_NOTIFICATION:
        notification = {
          title: notificationParams.originalTitle || "",
          body: notificationParams.originalBody || "",
        };
        break;
      // Connection
      case NotificationEventType.CONTACT_ARCHIVED:
        notification = {
          title: t("Contact deactivated"),
          body: t("{{contactName}} archived your connection", {
            contactName: notificationParams.contactName,
          }),
        };
        break;
      case NotificationEventType.CONTACT_REMOVED:
        notification = {
          title: t("Contact removed"),
          body: t(
            "{{facilityPublicId}} disabled contact with {{contactName}}",
            {
              facilityPublicId: notificationParams.facilityPublicId,
              contactName: notificationParams.contactName,
            }
          ),
        };
        break;
      case NotificationEventType.CONTACT_REQUEST_APPROVED:
        notification = {
          title: t("Contact request approved"),
          body: t(
            "{{facilityPublicId}} approved contact with {{contactName}}",
            {
              facilityPublicId: notificationParams.facilityPublicId,
              contactName: notificationParams.contactName,
            }
          ),
        };
        break;
      case NotificationEventType.CONTACT_REQUEST_CREATED:
        notification = {
          title: t("{{contactName}} has requested you as a contact", {
            contactName: notificationParams.contactName,
          }),
          body: t(
            "You can start talking with them once {{facilityPublicId}} approves their request",
            {
              facilityPublicId: notificationParams.facilityPublicId,
            }
          ),
        };
        break;
      case NotificationEventType.CONTACT_REQUEST_REJECTED:
        notification = {
          title: t("Your contact request with {{contactName}} was rejected", {
            contactName: notificationParams.contactName,
          }),
          body:
            user.__typename === "Inmate"
              ? t(
                  "{{contactFirstName}} has received instructions on how to re-add you.",
                  {
                    contactFirstName: notificationParams.contactFirstName,
                  }
                )
              : notificationParams.statusDetails ||
                t("Please resubmit your ID"),
        };
        break;
      // Connection restriction
      case NotificationEventType.CONNECTION_RESTRICTION_CREATED:
        notification = {
          title: t(
            "{{facilityPublicId}} suspended {{service}} with {{contactName}}",
            {
              facilityPublicId: notificationParams.facilityPublicId,
              service: facilityServiceLabel(
                notificationParams.service.toUpperCase()
              ),
              contactName: notificationParams.contactName,
            }
          ),
          body:
            (notificationParams.expirationDate
              ? t("This feature will return after {{expirationDate}}.", {
                  expirationDate: shortMonthDateFormat.format(
                    new Date(notificationParams.expirationDate)
                  ),
                })
              : t("We will notify you when this feature is available again")) +
            (notificationParams.affectedMeetingsCount > 0
              ? `. ${t("You have been removed from upcoming events.")}`
              : ""),
        };
        break;
      case NotificationEventType.CONNECTION_RESTRICTION_REMOVED:
        notification = {
          title:
            notificationParams.service === "emessaging"
              ? t("{{service}} with {{contactName}} is available again", {
                  service: facilityServiceLabel(
                    notificationParams.service.toUpperCase()
                  ),
                  contactName: notificationParams.contactName,
                })
              : t("{{service}} with {{contactName}} are available again", {
                  service: facilityServiceLabel(
                    notificationParams.service.toUpperCase()
                  ),
                  contactName: notificationParams.contactName,
                }),
          body: t("You may resume using {{service}}", {
            service: facilityServiceLabel(
              notificationParams.service.toUpperCase()
            ),
          }),
        };
        break;
      // ID Approval
      case NotificationEventType.ID_APPROVAL_REQUEST:
        notification = {
          title: t("Provide your ID for approval"),
          body: notificationParams.reason || "",
        };
        break;
      case NotificationEventType.ID_APPROVAL_REMINDER:
        notification = {
          title: t("Provide your ID for approval"),
          body: t("Upload your ID by {{dueDate}} to be approved for visits.", {
            dueDate: shortMonthDateFormat.format(
              new Date(notificationParams.dueDate)
            ),
          }),
        };
        break;
      case NotificationEventType.ID_APPROVAL_REJECTED:
        notification = {
          title: t("{{systemName}} rejected your ID", {
            systemName: notificationParams.systemName,
          }),
          body: notificationParams.reason || "",
        };
        break;
      // Message
      case NotificationEventType.MESSAGE_CREATED:
        notification = {
          title: t("{{contactName}} sent you {{count}} new messages.", {
            contactName: notificationParams.contactName,
            count: notificationParams.messageCount,
          }),
          body: notificationParams.messagePreview || "",
        };
        break;
      case NotificationEventType.MESSAGE_REJECTED:
        notification = {
          title: t("Your message was rejected"),
          body: t(
            "Looks like {{facilityPublicId}} blocked a message you tried to send. Tap to review.",
            {
              facilityPublicId: notificationParams.facilityPublicId,
            }
          ),
        };
        break;
      // Meeting
      case NotificationEventType.MEETING_APPROVED:
        notification = {
          title: t("Your {{meetingType}} with {{participants}} was approved", {
            meetingType: meetingTypeLabel(
              notificationParams.meetingType.toUpperCase() as MeetingType
            ),
            participants: notificationParams.participants.join(", "),
          }),
          body: `${yearMonthDate(
            new Date(notificationParams.scheduledStart)
          )} @ ${formatTime(new Date(notificationParams.scheduledStart))}`,
        };
        break;
      case NotificationEventType.MEETING_CANCELED:
        notification = {
          title:
            notificationParams.meetingType.toUpperCase() === MeetingType.Webinar
              ? t(
                  "Your webinar {{meetingTitle}} on {{scheduledStart}} was canceled",
                  {
                    meetingTitle: notificationParams.meetingTitle || "",
                    scheduledStart: shortMonthDateTime(
                      new Date(notificationParams.scheduledStart)
                    ),
                  }
                )
              : t("{{cancelerName}} canceled a visit with you", {
                  cancelerName:
                    notificationParams.cancelerName ||
                    notificationParams.contactName ||
                    t("your contact"),
                }),
          body:
            notificationParams.meetingType.toUpperCase() === MeetingType.Webinar
              ? t("Canceled by {{cancelerName}}", {
                  cancelerName:
                    notificationParams.cancelerName ||
                    notificationParams.contactName ||
                    t("your contact"),
                })
              : `${yearMonthDate(
                  new Date(notificationParams.scheduledStart)
                )} @ ${formatTime(
                  new Date(notificationParams.scheduledStart)
                )}`,
        };
        break;
      case NotificationEventType.MEETING_CANCELED_WITHOUT_KIOSKS:
        notification = {
          title: t(
            "Your {{meetingType}} with {{participants}} on {{scheduledStart}} was canceled",
            {
              meetingType: meetingTypeLabel(
                notificationParams.meetingType.toUpperCase() as MeetingType
              ),
              participants: notificationParams.participants.join(", "),
              scheduledStart: shortMonthDateTime(
                new Date(notificationParams.scheduledStart)
              ),
            }
          ),
          body: t("The assigned kiosk is no longer available"),
        };
        break;
      case NotificationEventType.MEETING_CREATED:
        notification = {
          title: t("{{schedulerName}} scheduled a {{meetingType}} with you", {
            meetingType: meetingTypeLabel(
              notificationParams.meetingType.toUpperCase() as MeetingType
            ),
            schedulerName: notificationParams.schedulerName,
          }),
          body: `${yearMonthDate(
            new Date(notificationParams.scheduledStart)
          )} @ ${formatTimeRange(
            new Date(notificationParams.scheduledStart),
            new Date(notificationParams.scheduledEnd)
          )}`,
        };
        break;
      case NotificationEventType.MEETING_MISSED:
        notification = {
          title: t("You missed a call from {{contactName}}", {
            contactName: notificationParams.contactName,
          }),
          body: t("Tap to open"),
        };
        break;
      case NotificationEventType.MEETING_REJECTED:
        notification = {
          title: t(
            "Your {{meetingType}} with {{participants}} on {{scheduledStart}} was declined",
            {
              meetingType: meetingTypeLabel(
                notificationParams.meetingType.toUpperCase() as MeetingType
              ),
              participants: notificationParams.participants.join(", "),
              scheduledStart: shortMonthDateTime(
                new Date(notificationParams.scheduledStart)
              ),
            }
          ),
          body:
            user.__typename === "Inmate"
              ? t("They may be able to reschedule")
              : t("You may be able to reschedule"),
        };
        break;
      case NotificationEventType.MEETING_REMINDER:
        notification = {
          title:
            notificationParams.meetingType.toUpperCase() === MeetingType.Webinar
              ? t("Your webinar is starting soon")
              : t(
                  "Your {{meetingType}} with {{participants}} is starting soon",
                  {
                    meetingType: meetingTypeLabel(
                      notificationParams.meetingType.toUpperCase() as MeetingType
                    ),
                    participants: notificationParams.participants.join(", "),
                  }
                ),
          body: t("Today @ {{startTime}}", {
            startTime: formatTime(new Date(notificationParams.scheduledStart)),
          }),
        };
        break;
      case NotificationEventType.MEETING_TERMINATED:
        notification = {
          title: t(
            "Your {{meetingType}} with {{participants}} on {{scheduledStart}} was ended",
            {
              meetingType: meetingTypeLabel(
                notificationParams.meetingType.toUpperCase() as MeetingType
              ),
              participants: notificationParams.participants.join(", "),
              scheduledStart: shortMonthDateTime(
                new Date(notificationParams.scheduledStart)
              ),
            }
          ),
          body: notificationParams.reason || "",
        };
        break;
      case NotificationEventType.MEETING_UPDATED:
        notification = {
          title: t("The time of your {{meetingType}} has been updated", {
            meetingType: meetingTypeLabel(
              notificationParams.meetingType.toUpperCase() as MeetingType
            ),
          }),
          body: t("Your {{meetingType}} will now take place at {{startTime}}", {
            meetingType: meetingTypeLabel(
              notificationParams.meetingType.toUpperCase() as MeetingType
            ),
            startTime: `${shortMonthDate(
              new Date(notificationParams.scheduledStart)
            )} @ ${formatTimeRange(
              new Date(notificationParams.scheduledStart),
              new Date(notificationParams.scheduledEnd)
            )}`,
          }),
        };
        break;
      case NotificationEventType.MEETING_PARTICIPANT_REMOVED:
        notification = {
          title: t("{{contactName}} was removed from your webinar", {
            contactName: notificationParams.contactName,
          }),
          body: t(
            "{{facilityPublicId}} removed {{contactName}} from your webinar. If this is unexpected, please contact {{facilityPublicId}} for more information.",
            {
              facilityPublicId: notificationParams.facilityPublicId,
              contactName: notificationParams.contactName,
            }
          ),
        };
        break;
      case NotificationEventType.MEETING_PARTICIPANT_UPDATED:
        notification = {
          title: t(
            "{{contactName}} added you to a {{meetingType}} with {{participants}}",
            {
              contactName: notificationParams.contactName,
              meetingType: meetingTypeLabel(
                notificationParams.meetingType.toUpperCase() as MeetingType
              ),
              participants: notificationParams.participants.join(", "),
            }
          ),
          body: `${yearMonthDate(
            new Date(notificationParams.scheduledStart)
          )} @ ${formatTimeRange(
            new Date(notificationParams.scheduledStart),
            new Date(notificationParams.scheduledEnd)
          )}`,
        };
        break;
      // Organization
      case NotificationEventType.ORGANIZATION_MEMBERSHIP_CREATED:
        notification = {
          title:
            notificationParams.recipient === "members"
              ? t("{{organizationName}} is growing", {
                  organizationName: notificationParams.organizationName,
                })
              : t("You have been added to {{organizationName}}", {
                  organizationName: notificationParams.organizationName,
                }),
          body:
            notificationParams.recipient === "members"
              ? t("{{memberName}} has joined", {
                  memberName: notificationParams.memberName,
                })
              : t(
                  "You can now request access to special services at participating facilities."
                ),
        };
        break;
      // Screenshots
      case NotificationEventType.SCREENSHOTS_VIEWED:
        notification = {
          title: t("Video call screenshots have been viewed"),
          body: t(
            "Low-quality screenshots taken during your {{meetingType}} on {{scheduledStart}} have been viewed.",
            {
              meetingType: meetingTypeLabel(
                notificationParams.meetingType.toUpperCase() as MeetingType
              ),
              scheduledStart: shortMonthDateTime(
                new Date(notificationParams.scheduledStart)
              ),
            }
          ),
        };
        break;
      // Voice calls
      case NotificationEventType.VOICE_CALL_CALLING:
        notification = {
          title: t("{{contactName}} is calling you...", {
            contactName: notificationParams.contactName,
          }),
          body: t("Tap to open"),
        };
        break;
      default:
        notification = {
          title: notificationParams.originalTitle || "",
          body: notificationParams.originalBody || "",
        };
    }
    return {
      title: capitalize(notification.title),
      body: capitalize(notification.body),
    };
  } catch (error) {
    // Report but do not fail
    // This should happen only in case of malformed data
    Sentry.captureException(error);
  }
  return { title: "", body: "" };
}
