import { Launch } from "@mui/icons-material";
import {
  Box,
  FormControlLabel,
  Link,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import StepperFormWrapper from "../lib/StepperFormWrapper";
import { StepperType } from "../lib/StepperWrapper";
import { ScreenTitle } from "../lib/typography";
import { SearchResult } from "./types";

type Props = {
  selectedResult: SearchResult;
  stepper: StepperType;
  onNext: () => void;
};

type SingleFacilityProps = {
  approved: boolean | null;
  onConfirm: (approved: boolean) => void;
};

function NotApprovedLabel({
  approved,
  url,
  facilityPublicId,
}: {
  facilityPublicId: string;
  approved: boolean | null;
  url: string;
}) {
  const { t } = useTranslation();
  return (
    <Box pt={1}>
      {t("No, I have not been approved")}
      {approved === false && (
        <Stack spacing={2} mt={2}>
          <Typography>
            {t(
              "Please complete {{facilityPublicId}}'s visitor application. {{facilityPublicId}} will not approve your Ameelio Connect contact request until the application process is finished.",
              {
                facilityPublicId,
              }
            )}
          </Typography>
          <Link href={url} target="_blank" sx={{ textDecoration: "none" }}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography
                sx={{
                  textDecoration: "underline",
                }}
              >
                {t("Open visitor application")}
              </Typography>
              <Launch fontSize="inherit" />
            </Stack>
          </Link>
        </Stack>
      )}
    </Box>
  );
}

function SingleFacilityVisitationForm({
  selectedResult,
  approved,
  onConfirm,
}: Omit<Props, "stepper" | "onNext"> & SingleFacilityProps) {
  const { t } = useTranslation();
  const result = selectedResult;
  const { facilityName, facilityPublicId } = result;
  return (
    <>
      <ScreenTitle>{t("Have you been approved to visit?")}</ScreenTitle>
      <Typography color="text.primary" mt={2}>
        {t(
          "{{facilityName}} ({{facilityPublicId}}) requires visitors to fill out a separate visitor approval application before using Ameelio Connect.",
          { facilityName, facilityPublicId }
        )}
      </Typography>
      <Box
        my={8}
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        flex={1}
      >
        <RadioGroup
          name="visitorApproval"
          value={approved !== null && (approved ? "approved" : "notApproved")}
          onChange={(_, value) => {
            onConfirm(value === "approved");
          }}
        >
          <FormControlLabel
            value="approved"
            control={<Radio />}
            label={t("Yes, I have been approved")}
          />
          <FormControlLabel
            value="notApproved"
            sx={{ alignItems: "flex-start" }}
            control={<Radio />}
            label={
              <NotApprovedLabel
                approved={approved}
                url={result.facilityVisitationFormLink || ""}
                facilityPublicId={facilityPublicId}
              />
            }
          />
        </RadioGroup>
      </Box>
    </>
  );
}

export default function FacilityVisitationFormLinks({
  selectedResult,
  stepper,
  onNext,
}: Props) {
  const [approved, setApproved] = useState<boolean | null>(null);
  return (
    <StepperFormWrapper
      stepper={stepper({
        loading: false,
        disabled: !approved,
      })}
      handleSubmit={(event) => {
        event.preventDefault();
        onNext();
      }}
    >
      {selectedResult.facilityVisitationFormLink && (
        <SingleFacilityVisitationForm
          selectedResult={selectedResult}
          onConfirm={(checked: boolean) => setApproved(checked)}
          approved={approved}
        />
      )}
    </StepperFormWrapper>
  );
}
