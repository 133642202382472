import { ClientStatus, ProducerLabel } from "@ameelio/connect-call-client";
import { useSnackbarContext } from "@ameelio/ui";
import { Box, SxProps, Theme, Typography } from "@mui/material";
import * as Sentry from "@sentry/react";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import useSound from "use-sound";
import { Correspondent, Interval, PrivacyLevel } from "../../api/graphql";
import { DeviceInfo, useDeviceChanges } from "../../lib/useDevices";
import useMediaPermissions from "../../lib/useMediaPermissions";
import { formatTimeLeft } from "../../lib/useTimeLeft";
import useTimer from "../../lib/useTimer";
import { useCurrentUser } from "../../SessionBoundary";
import { darkPalette, ltrTheme } from "../../theme";
import { GetMeetingInfoQuery } from "../GetMeetingInfo.generated";
import AudioIndicatingAvatar from "./AudioIndicatingAvatar";
import CallControls from "./CallControls";
import CaptionBox from "./CaptionBox";
import CaptionsContainer from "./CaptionsContainer";
import ParticipantAudio from "./ParticipantAudio";
import ParticipantPlaceholder from "./ParticipantPlaceholder";
import DisconnectedSound from "./sounds/VoiceCallDisconnected.mp3";
import OneMinuteLeftSound from "./sounds/VoiceCallOneMinuteLeft.mp3";
import { ConnectionInfo } from "./types/ConnectionInfo";
import useCallAlerts from "./useCallAlerts";
import VoiceCallContext from "./VoiceCallContext";

const style: SxProps<Theme> = {
  px: 2,
  py: 2,
  height: "100%",
  backgroundColor: darkPalette.background.default,
  overflow: "hidden",
  position: "relative",
};

enum TimeBreakpoint {
  FIVE_MINUTES = "FIVE_MINUTES",
  ONE_MINUTE = "ONE_MINUTE",
}

const breakpointTimes: Record<TimeBreakpoint, number> = {
  [TimeBreakpoint.FIVE_MINUTES]: 5 * 60 * 1000,
  [TimeBreakpoint.ONE_MINUTE]: 1 * 60 * 1000,
};

export type Props = {
  // User
  user: {
    id: string;
    firstName: string;
    lastName: string;
  };

  // Meeting data
  correspondents: Pick<
    Correspondent,
    "id" | "firstName" | "lastName" | "fullName"
  >[];
  connections: GetMeetingInfoQuery["meeting"]["connections"];
  interval?: Pick<Interval, "startAt" | "endAt">;
  privacyLevel: PrivacyLevel;

  // Connection information
  connectionInfo: ConnectionInfo;

  onLeave: () => void;
  alerts: boolean;
  onToggleAlerts: () => void;
  onPeerJoined: () => void;
};

function VoiceCallInterface({
  connectionInfo,
  user,
  correspondents,
  connections,
  interval,
  onLeave,
  alerts,
  onToggleAlerts,
  onPeerJoined,
  privacyLevel,
}: Props) {
  const { t } = useTranslation();
  const snackbarContext = useSnackbarContext();

  // We currently assume that voice calls are only ever with one person
  const otherMeetingUser = correspondents.filter((x) => x.id !== user.id)[0];

  const correspondentById = Object.fromEntries(
    correspondents.map((c) => [c.id, c])
  );
  const currentUser = useCurrentUser();

  const {
    error,

    // Things both have:
    clientStatus,
    localProducers,
    pauseProducer,
    resumeProducer,
    toggleCaption,
    messages,
    syncedInterval,

    peers, // Currently only one peer allowed; future-proofed
    captionsTimeline,
    logClientEvent,
  } = connectionInfo;

  /*
  const devices = useDevices(false);
  useEffect(() => {
    console.warn("Initial Devices:", devices);
  }, [devices.length]);
  */

  const onDeviceAdded = useCallback(
    (device: DeviceInfo) => {
      if (clientStatus === ClientStatus.connected) {
        logClientEvent(`Device added: ${device.label} - ${device.kind}`);
      }
    },
    [logClientEvent, clientStatus]
  );
  const onDeviceRemoved = useCallback(
    (device: DeviceInfo) => {
      if (clientStatus === ClientStatus.connected) {
        logClientEvent(`Device removed: ${device.label} - ${device.kind}`);
      }
    },
    [logClientEvent, clientStatus]
  );
  // Handle device changes (e.g. unplugging a microphone)
  useDeviceChanges(onDeviceAdded, onDeviceRemoved);

  const { cameraDisabled, microphoneDisabled } = useMediaPermissions();
  useEffect(() => {
    if (clientStatus === ClientStatus.connected) {
      logClientEvent(
        `Device availability (status: ${clientStatus}) - Microphone: ${!microphoneDisabled}`
      );
    }
  }, [cameraDisabled, microphoneDisabled, clientStatus, logClientEvent]);

  const toggleAudio = useCallback(() => {
    try {
      if (localProducers.audio?.paused) resumeProducer(ProducerLabel.audio);
      else pauseProducer(ProducerLabel.audio);
    } catch (e) {
      if (e instanceof Error && e.message !== "Not connected") {
        Sentry.captureException(e);
      }
    }
  }, [pauseProducer, resumeProducer, localProducers]);

  const [showCaptions, setShowCaptions] = useState<boolean>(false);

  if (error) throw error;

  const peer = Object.values(peers)[0];

  const [peerHasConnected, setPeerHasConnected] = useState<boolean>(false);

  useCallAlerts(messages);

  // Track last known set of unjoined peers
  // and trigger onPeerJoined event when a new one appears.
  const knownPeers = useRef<Set<string>>(new Set());
  useEffect(() => {
    Object.keys(peers).forEach((key) => {
      if (!knownPeers.current.has(key)) onPeerJoined();
    });
    knownPeers.current = new Set(Object.keys(peers));
  }, [peers, onPeerJoined]);

  useEffect(() => {
    if (!peerHasConnected) {
      setPeerHasConnected(!!peer);
    }
  }, [peer, peerHasConnected]);

  const time = useTimer(
    interval
      ? {
          startAt: (syncedInterval || interval).startAt,
          endAt: (syncedInterval || interval).endAt,
        }
      : null
  );

  const timeLeft = formatTimeLeft({
    countDownAt: 120000,
    ...time,
  });

  // SC-7028 - play one minute left sound at five minutes left too
  const [playMinutesRemainingAlert] = useSound(OneMinuteLeftSound, {
    volume: 0.5,
  });

  // SC-9230 - loop a sound while waiting for self/other client to reconnect
  const playDisconnectedRef = useRef<NodeJS.Timeout>();
  const [playDisconnected, { stop: stopDisconnectedSound }] = useSound(
    DisconnectedSound,
    {
      volume: 0.5,
      loop: true,
      // `interrupt` ensures that if the sound starts again before it's
      // ended, it will truncate it. Otherwise, the sound can overlap.
      interrupt: true,
    }
  );

  const breakpointSounds = useMemo(
    () => ({
      [TimeBreakpoint.FIVE_MINUTES]: playMinutesRemainingAlert,
      [TimeBreakpoint.ONE_MINUTE]: playMinutesRemainingAlert,
    }),
    [playMinutesRemainingAlert]
  );

  const breakpointAlerts = useMemo(
    () => ({
      [TimeBreakpoint.FIVE_MINUTES]: () =>
        snackbarContext.alert("info", t("5 minutes left")),
      [TimeBreakpoint.ONE_MINUTE]: () =>
        snackbarContext.alert("info", t("1 minute left")),
    }),
    [snackbarContext, t]
  );

  const [alertsPlayed, setAlertsPlayed] = useState<TimeBreakpoint[]>([]);

  useEffect(() => {
    const nextBreakpoint = Object.keys(breakpointTimes).find(
      (key) =>
        time.remaining <= breakpointTimes[key as TimeBreakpoint] &&
        // Must be within 2 seconds of the breakpoint
        // (useful if a call starts with a total duration
        // less than the first breakpoint)
        breakpointTimes[key as TimeBreakpoint] - time.remaining <= 2000 &&
        !alertsPlayed.includes(key as TimeBreakpoint)
    ) as TimeBreakpoint;

    if (nextBreakpoint) {
      if (alerts) {
        // Play the sound
        breakpointSounds[nextBreakpoint]();
        // Show the toast
        breakpointAlerts[nextBreakpoint]();
      }
      setAlertsPlayed(alertsPlayed.concat([nextBreakpoint]));
    }
  }, [time, alerts, alertsPlayed, breakpointSounds, breakpointAlerts]);

  // used to compute a user-friendly call status label (handles local and peer disconnect too)
  const statusLabel = useMemo(() => {
    // always prefer showing the local user they're disconnected if that's the case
    if (clientStatus === ClientStatus.disconnected) return t("Disconnected");
    // otherwise proceed
    if (!peer && !peerHasConnected) return t("Calling...");
    if (!peer && peerHasConnected)
      return t("Waiting for {{firstName}} to reconnect", {
        firstName: otherMeetingUser.firstName || t("your contact"),
      });
    if (peer && peerHasConnected) return timeLeft;
    return "--";
  }, [clientStatus, otherMeetingUser, peer, peerHasConnected, t, timeLeft]);

  // used to loop a sound while waiting for this client
  // or the other client to reconnect
  useEffect(() => {
    if (
      // local disconnect
      clientStatus === ClientStatus.disconnected ||
      // peer disconnect
      (!peer && peerHasConnected)
    )
      // delay a bit to avoid overlap
      // with the peer exit sound
      playDisconnectedRef.current = setTimeout(playDisconnected, 1250);

    return () => {
      // clear the timeout to ensure the sound can't
      // start after the component unmounts
      if (playDisconnectedRef.current)
        clearTimeout(playDisconnectedRef.current);
      stopDisconnectedSound();
    };
  }, [
    clientStatus,
    peer,
    peerHasConnected,
    playDisconnected,
    stopDisconnectedSound,
  ]);

  return (
    <Box sx={{ height: 1, width: "100%" }}>
      <Box sx={style}>
        <VoiceCallContext
          contact={otherMeetingUser}
          interval={
            !connections?.[0]?.organizationMembership ? interval : undefined // Calls to providers have no time limit
          }
          privacyLevel={privacyLevel}
        />
        <ParticipantPlaceholder styleOverrides={{ borderRadius: "initial" }}>
          <AudioIndicatingAvatar
            user={otherMeetingUser}
            stream={
              peer?.consumers.audio?.stream || peer?.consumers.video?.stream
            }
          />
          <ParticipantAudio
            srcObject={
              peer?.consumers.audio?.stream || peer?.consumers.video?.stream
            }
            autoPlay
          />
          <Box pt={1}>
            <Typography>{statusLabel}</Typography>
          </Box>
        </ParticipantPlaceholder>
        {showCaptions && captionsTimeline.length && (
          <CaptionsContainer>
            {captionsTimeline.map((segment) => (
              <CaptionBox
                key={segment.timestamp}
                user={
                  segment.user && correspondentById[segment.user]
                    ? correspondentById[segment.user]
                    : {
                        firstName: currentUser.firstName,
                        lastName: currentUser.lastName,
                        fullName: t("You"),
                      }
                }
                message={segment.text}
              />
            ))}
          </CaptionsContainer>
        )}
        <Box
          sx={{
            display: "flex",
            width: 1,
            position: "absolute",
            zIndex: "modal",
            left: 0,
            bottom: ltrTheme.spacing(8),
            px: 2,
            height: 0,
          }}
        >
          <Box sx={{ flexGrow: 1, display: "flex", justifyContent: "center" }}>
            <CallControls
              muted={!!localProducers.audio?.paused}
              hidden={!!localProducers.video?.paused}
              captions={showCaptions}
              captionsDisabled={
                !toggleCaption || clientStatus !== ClientStatus.connected
              }
              alerts={alerts}
              onToggleAudio={toggleAudio}
              onToggleAlerts={onToggleAlerts}
              onLeave={onLeave}
              onToggleCaptions={() => {
                if (toggleCaption) {
                  const newState = !showCaptions;
                  toggleCaption(newState).catch((e) => {
                    if (e instanceof Error && e.message !== "Not connected") {
                      Sentry.captureException(e);
                    }
                  });
                  setShowCaptions(newState);
                }
              }}
              leaveCallLabel={t("End call")}
              leaveCallDisabled={clientStatus === ClientStatus.initializing}
              disabledReason={t("The call is still initializing")}
              styleOverrides={{
                transform: "translateY(-50%)",
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default VoiceCallInterface;
