import { Button, ScreenContainer } from "@ameelio/ui";
import { useQuery } from "@apollo/client";
import { Card, Link } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import ConnectionStatusChip from "../Contacts/ConnectionStatusChip";
import OrganizationMembershipStatusChip from "../Contacts/OrganizationMembershipStatusChip";
import { ContentSkeleton } from "../lib/closet";
import DetailsStack, { DetailsStackItem } from "../lib/DetailsStack";
import OrganizationMembershipLogo from "../lib/OrganizationMembershipLogo";
import ScreenSection from "../lib/ScreenSection";
import { yearShortMonthDateTime } from "../lib/timeFormats";
import { ScreenTitle } from "../lib/typography";
import useBoolean from "../lib/useBoolean";
import DeleteVisitorDialog from "./DeleteVisitorDialog";
import EditVisitorEmail from "./EditVisitorEmail";
import { GetVisitorDocument } from "./GetVisitor.generated";

export default function VisitorScreen() {
  const { t } = useTranslation();
  const deleteVisitorDialog = useBoolean();

  const { visitorId } = useParams<{ visitorId: string }>();
  if (!visitorId) throw new Error("missing visitor id");

  const { data, error } = useQuery(GetVisitorDocument, {
    fetchPolicy: "cache-and-network",
    variables: { visitorId },
  });
  if (error) throw error;
  if (!data) return <ContentSkeleton />;

  const { visitor } = data;
  const devices =
    data?.visitor?.devices
      .filter((d) => d.active)
      .sort((a, b) => b.updatedAt - a.updatedAt) ?? []; // accessed most recently shown first

  return (
    <ScreenContainer>
      <ScreenTitle>{visitor.fullName}</ScreenTitle>
      <ScreenSection title="">
        <DetailsStack>
          <EditVisitorEmail visitor={visitor} />
          <DetailsStackItem
            label={t("Date of birth")}
            value={visitor.dateOfBirth || "-"}
          />
          <DetailsStackItem label={t("Connect dashboard URL")}>
            <Link
              href={`${import.meta.env.VITE_DASHBOARD_URL}/visitors/${
                visitor.id
              }`}
              target="_blank"
            >
              {t("View profile")}
            </Link>
          </DetailsStackItem>
          <DetailsStackItem label={t("Actions")}>
            <Button
              variant="contained"
              color="error"
              onClick={deleteVisitorDialog.enable}
            >
              {t("Delete account")}
            </Button>
          </DetailsStackItem>
        </DetailsStack>
      </ScreenSection>
      {visitor.connections.length > 0 && (
        <ScreenSection title={t("Contacts")}>
          {visitor.connections.map((c) => (
            <Card key={c.id} variant="outlined" sx={{ p: 2, m: 2 }}>
              <DetailsStack>
                <DetailsStackItem
                  label={t("Resident")}
                  value={`${c.inmate.fullName} (${c.inmate.inmateIdentification})`}
                />
                <DetailsStackItem label={t("Status")}>
                  <ConnectionStatusChip status={c.status} />
                </DetailsStackItem>
                <DetailsStackItem
                  label={t("Facility")}
                  value={`${c.inmate.facility.name} (${c.inmate.facility.publicId})`}
                />
              </DetailsStack>
            </Card>
          ))}
        </ScreenSection>
      )}
      {visitor.organizationMemberships.length > 0 && (
        <ScreenSection title={t("Organization Memberships")}>
          {visitor.organizationMemberships.map((om) => (
            <Card key={om.id} variant="outlined" sx={{ p: 2, m: 2 }}>
              <DetailsStack>
                <DetailsStackItem
                  label={t("Organization")}
                  value={om.organization.name}
                />
                <DetailsStackItem
                  label={t("Type")}
                  value={om.organization.type}
                />
                <DetailsStackItem label={t("Role")} value={om.role} />
                <DetailsStackItem label={t("Status")}>
                  <OrganizationMembershipStatusChip status={om.status} />
                </DetailsStackItem>
                <DetailsStackItem
                  label={t("Created")}
                  value={yearShortMonthDateTime(om.createdAt)}
                />
                <DetailsStackItem
                  label={t("Last Updated")}
                  value={yearShortMonthDateTime(om.updatedAt)}
                />
                {om.organization.logoImageUrl && (
                  <DetailsStackItem label={t("Logo")}>
                    <OrganizationMembershipLogo
                      membership={om}
                      user={visitor}
                    />
                  </DetailsStackItem>
                )}
              </DetailsStack>
            </Card>
          ))}
        </ScreenSection>
      )}
      {devices.length > 0 && (
        <ScreenSection title={t("Active Devices")}>
          {devices.map((d) => (
            <Card key={d.id} variant="outlined" sx={{ p: 2, m: 2 }}>
              <DetailsStack>
                <DetailsStackItem label={t("Type")} value={d.type} />
                <DetailsStackItem
                  label={t("App version")}
                  value={d.appVersion}
                />
                <DetailsStackItem
                  label={t("Last accessed")}
                  value={yearShortMonthDateTime(new Date(d.updatedAt))}
                />
                <DetailsStackItem
                  label={t("Registered")}
                  value={yearShortMonthDateTime(new Date(d.createdAt))}
                />
              </DetailsStack>
            </Card>
          ))}
        </ScreenSection>
      )}
      <DeleteVisitorDialog
        visitor={visitor}
        isOpen={deleteVisitorDialog.current}
        onClose={deleteVisitorDialog.disable}
      />
    </ScreenContainer>
  );
}
